import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Pagination,
  PaginationItem,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { DataGrid, useGridApiRef, GridToolbar } from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TableRowsIcon from "@mui/icons-material/TableRows";
import GridViewIcon from "@mui/icons-material/GridView";
import FilterListIcon from "@mui/icons-material/FilterList";
import Grid from "@mui/material/Grid";
import VideoCard from "./VideoCard";
import axios from "axios";
import { format } from "date-fns";

const Instagram = () => {
  const [view, setView] = useState("table");
  const [videoData, setVideoData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(50);
  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState("all");
  const [showFilters, setShowFilters] = useState(false);

  // States for filter options
  const [sponsors, setSponsors] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [writers, setWriters] = useState([]);

  // Filter state
  const [filters, setFilters] = useState({
    sponsor: "ALL",
    account: "ALL",
    writer: "ALL",
    minViews: "",
    maxViews: "",
    postedBefore: "",
    postedAfter: "",
    sortBy: "timestamp_desc",
  });

  const apiRef = useGridApiRef();

  useEffect(() => {
    fetchData();
    fetchFilterOptions();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/api/videos/Instagram");
      setVideoData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching Instagram data", error);
      setLoading(false);
    }
  };

  const fetchFilterOptions = async () => {
    try {
      const sponsorsResponse = await axios.get("/api/videos/sponsors");
      const accountsResponse = await axios.get("/api/videos/accounts");
      const writersResponse = await axios.get("/api/videos/writers");

      setSponsors(sponsorsResponse.data);
      setAccounts(accountsResponse.data);
      setWriters(writersResponse.data);
    } catch (error) {
      console.error("Error fetching filter options:", error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleViewChange = (event, nextView) => {
    setView(nextView);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleNewSearch = () => {
    fetchData();
  };

  const handleResetFilters = () => {
    setFilters({
      sponsor: "ALL",
      account: "ALL",
      writer: "ALL",
      minViews: "",
      maxViews: "",
      postedBefore: "",
      postedAfter: "",
      sortBy: "timestamp_desc",
    });
    setSearchText("");
  };

  // Sorting logic
  const sortData = (data) => {
    switch (filters.sortBy) {
      case "timestamp_asc":
        return data.sort((a, b) => new Date(a.created) - new Date(b.created));
      case "timestamp_desc":
        return data.sort((a, b) => new Date(b.created) - new Date(a.created));
      case "views_asc":
        return data.sort((a, b) => a.views_total - b.views_total);
      case "views_desc":
        return data.sort((a, b) => b.views_total - a.views_total);
      default:
        return data;
    }
  };

  const filteredData = videoData.filter((video) => {
    const matchesFilter =
      (
        (video.title && video.title.toLowerCase().includes(searchText.toLowerCase())) ||
        (video.url && video.url.toLowerCase().includes(searchText.toLowerCase()))
      ) &&
      (
        filter === "all" ||
        (filter === "sponsored" && video.name && video.name !== "No Sponsor") ||
        (filter === "non-sponsored" && (!video.name || video.name === "No Sponsor"))
      ) &&
      (
        (filters.sponsor === "ALL" || video.name === filters.sponsor) &&
        (filters.account === "ALL" || video.username === filters.account) &&
        (filters.writer === "ALL" || video.writername === filters.writer) &&
        (filters.minViews === "" || video.views_total >= parseInt(filters.minViews, 10)) &&
        (filters.maxViews === "" || video.views_total <= parseInt(filters.maxViews, 10)) &&
        (filters.postedAfter === "" || new Date(video.created) >= new Date(filters.postedAfter)) &&
        (filters.postedBefore === "" || new Date(video.created) <= new Date(filters.postedBefore))
      );

    return matchesFilter;
  });

  const sortedData = sortData(filteredData);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = sortedData.slice(indexOfFirstRecord, indexOfLastRecord);

  const columns = [
    { field: "video_id", headerName: "Video ID", width: 150 },
    { field: "url", headerName: "URL", width: 150 },
    {
      field: "details",
      headerName: "Details",
      width: 150,
      renderCell: (params) => (
        <a href={`/videos/${params.row.video_id}`}>Details</a>
      ),
    },
    { field: "name", headerName: "Sponsor", width: 150 },
    { field: "username", headerName: "Account", width: 100 },
    { field: "writername", headerName: "Writer", width: 100 },
    { field: "views_total", headerName: "Views", width: 100 },
    { field: "likes_total", headerName: "Likes", width: 100 },
    { field: "comments_total", headerName: "Comments", width: 100 },
    {
      field: "created",
      headerName: "Timestamp",
      width: 120,
      renderCell: (params) =>
        format(new Date(params.value), "dd/MM/yyyy, HH:mm"),
    },
  ];

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      {/* Title and Search Bar */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography variant="h5">Instagram Videos</Typography>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton aria-label="search">
            <SearchIcon />
          </IconButton>
          <TextField
            variant="outlined"
            size="small"
            label="Search by Title or URL"
            value={searchText}
            onChange={handleSearchChange}
            sx={{ width: 300 }}
          />
        </Box>
      </Box>

      {/* Toggle Buttons and Filter Toggle */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={handleViewChange}
            aria-label="view toggle"
          >
            <ToggleButton value="grid" aria-label="grid view">
              <GridViewIcon />
            </ToggleButton>
            <ToggleButton value="table" aria-label="table view">
              <TableRowsIcon />
            </ToggleButton>
          </ToggleButtonGroup>

          <IconButton
            aria-label="filter"
            onClick={() => setShowFilters(!showFilters)}
            sx={{
              ml: 1,
              borderRadius: 1,
              border: "1px solid #ccc",
            }}
          >
            <FilterListIcon />
          </IconButton>
        </Box>

        <ToggleButtonGroup
          value={filter}
          exclusive
          onChange={(event, newFilter) => setFilter(newFilter)}
          aria-label="filter toggle"
          size="small"
        >
          <ToggleButton value="all" aria-label="all videos">
            All
          </ToggleButton>
          <ToggleButton value="sponsored" aria-label="sponsored videos">
            Sponsored
          </ToggleButton>
          <ToggleButton value="non-sponsored" aria-label="non-sponsored videos">
            Non-Sponsored
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {/* Filters Panel */}
      {showFilters && (
        <Box
          sx={{
            mb: 2,
            p: 2,
            border: "1px solid #ccc",
            borderRadius: 2,
            maxHeight: 300,
            overflowY: "auto",
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "bold", mb: 1 }}>
            Filters
          </Typography>
          <FormControl variant="outlined" size="small" sx={{ minWidth: 100, mr: 2 }}>
            <InputLabel>Sponsor</InputLabel>
            <Select
              name="sponsor"
              value={filters.sponsor}
              onChange={handleFilterChange}
            >
              <MenuItem value="ALL">ALL</MenuItem>
              {sponsors.map((sponsor) => (
                <MenuItem key={sponsor.id} value={sponsor.name}>
                  {sponsor.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" size="small" sx={{ minWidth: 100, mr: 2 }}>
            <InputLabel>Account</InputLabel>
            <Select
              name="account"
              value={filters.account}
              onChange={handleFilterChange}
            >
              <MenuItem value="ALL">ALL</MenuItem>
              {accounts.map((account) => (
                <MenuItem key={account.id} value={account.username}>
                  {account.username}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" size="small" sx={{ minWidth: 100, mr: 2 }}>
            <InputLabel>Writer</InputLabel>
            <Select
              name="writer"
              value={filters.writer}
              onChange={handleFilterChange}
            >
              <MenuItem value="ALL">ALL</MenuItem>
              {writers.map((writer) => (
                <MenuItem key={writer.id} value={writer.name}>
                  {writer.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Min Views"
            name="minViews"
            value={filters.minViews}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
            type="number"
            sx={{ minWidth: 100, mr:2 }}
          />
          <TextField
            label="Max Views"
            name="maxViews"
            value={filters.maxViews}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
            type="number"
            sx={{ minWidth: 100, mr: 2 }}
          />
          <TextField
            label="Posted After"
            name="postedAfter"
            value={filters.postedAfter}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
            type="date"
            sx={{ minWidth: 100, mr: 2 }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Posted Before"
            name="postedBefore"
            value={filters.postedBefore}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
            type="date"
            sx={{ minWidth: 100, mr: 2 }}
            InputLabelProps={{ shrink: true }}
          />

          {/* Sort by Radio Group */}
          <Box sx={{ mt: 1 }}>
            <Typography variant="body2" sx={{ mb: 1, fontWeight: "bold" }}>
              Sort By
            </Typography>
            <RadioGroup
              row
              name="sortBy"
              value={filters.sortBy}
              onChange={handleFilterChange}
            >
              <FormControlLabel
                value="timestamp_desc"
                control={<Radio size="small" />}
                label="Timestamp Desc"
              />
              <FormControlLabel
                value="timestamp_asc"
                control={<Radio size="small" />}
                label="Timestamp Asc"
              />
              <FormControlLabel
                value="views_desc"
                control={<Radio size="small" />}
                label="Views Desc"
              />
              <FormControlLabel
                value="views_asc"
                control={<Radio size="small" />}
                label="Views Asc"
              />
            </RadioGroup>
          </Box>

          {/* New Search and Reset Buttons */}
          <Box sx={{ mt: 2, display: "flex", gap: 1 }}>
            <Button variant="contained" onClick={handleNewSearch} size="small">
              New Search
            </Button>
            <Button variant="outlined" onClick={handleResetFilters} size="small">
              Reset
            </Button>
          </Box>
        </Box>
      )}

      {loading ? (
        <Typography>Loading...</Typography>
      ) : view === "table" ? (
        <Box sx={{ height: 600 }}>
          <DataGrid
            rows={currentRecords}
            columns={columns}
            pagination
            rowsPerPageOptions={[10, 25, 50]}
            loading={loading}
            apiRef={apiRef}
            disableRowSelectionOnClick
            slots={{ toolbar: GridToolbar }}
            getRowId={(row) => row.video_id}
          />
        </Box>
      ) : (
        <>
          <Grid container spacing={1}>
            {currentRecords.map((video) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={3}
                key={video.video_id}
              >
                <VideoCard video={video} />
              </Grid>
            ))}
          </Grid>

          {/* Pagination */}
          <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <Pagination
              count={Math.ceil(sortedData.length / recordsPerPage)}
              page={currentPage}
              onChange={(event, page) => setCurrentPage(page)}
              renderItem={(item) => (
                <PaginationItem
                  slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                  {...item}
                />
              )}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default Instagram;