import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../logo2.png';
import '../styles.css';
import { FaUser, FaLock, FaPen, FaEnvelope } from 'react-icons/fa';

const WriterRegister = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [writerName, setWriterName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [activeTab, setActiveTab] = useState('register'); // State to manage active tab
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/auth/registerwriter', {
        username,
        password,
        name: writerName,
        email,
        role:'writer'
      });

      const data = response.data;
      if (data.success) {
        setMessage('Account creation ticket submitted - usual response time 24-48 hours.');
      } else {
        setMessage('Registration failed');
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setMessage('Username already exists.');
      } else {
        setMessage('Registration failed. Please try again later.');
      }
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === 'login') {
      navigate('/login');
    } else {
      navigate('/register');
    }
  };

  return (
    <div className="fullscreen-container">
      <div className="login-box">
        <img src={logo} alt="Logo" className="login-logo" />
        <div className="login-title">Welcome to Plotpointe</div>

        <div className="toggle-container">
          <button
            className={`toggle-button ${activeTab === 'login' ? 'active' : ''}`}
            onClick={() => handleTabClick('login')}
          >
            Log In
          </button>
          <button
            className={`toggle-button ${activeTab === 'register' ? 'active' : ''}`}
            onClick={() => handleTabClick('register')}
          >
           Register
          </button>
        </div>

        <form onSubmit={handleRegister}>
          <div className="input-container">
            <FaUser className="input-icon" />
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <FaLock className="input-icon" />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <FaPen className="input-icon" />
            <input
              type="text"
              placeholder="Writer Name"
              value={writerName}
              onChange={(e) => setWriterName(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <FaEnvelope className="input-icon" />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <button className="submit-button" type="submit">Submit Request</button>
        </form>

        {message && <div className="custom-form-message">{message}</div>}
      </div>
    </div>
  );
};

export default WriterRegister;

