import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";
import axios from "axios";


const AdminAnalytics
 = () => {
  const [loading, setLoading] = useState(true);
  const [platformData, setPlatformData] = useState([]);
  const [chartData, setChartData] = useState([]);

  // Fetch platform data for the DataGrid and chart
  const fetchPlatformData = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/api/videos/table"); // Your API for fetching aggregated data by date
      const platformVideos = response.data;

      // Transform platformVideos data for the DataGrid
      setPlatformData(platformVideos);

      // Set chart data for the line chart (this should contain date and views for each platform)
      setChartData(platformVideos);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching platform data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPlatformData();
  }, []);

  // Helper function to format numbers in the Y-axis
  const numberFormatter = (value) => {
    if (value >= 1000000) {
      return `${(value / 1000000).toFixed(1)}M`;
    } else if (value >= 1000) {
      return `${(value / 1000).toFixed(1)}K`;
    }
    return value;
  };

  return (
  
      <Box sx={{ p: 6 }}>
        <Typography variant="h5" sx={{ mb: 3 }}>
          Admin Dashboard
        </Typography>

        {/* Render the Line Chart for total views across platforms */}
        <Box sx={{ mb: 12 }}>
          <Typography variant="h6">Total Views Across Platforms</Typography>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={chartData}>
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="date" tickFormatter={(tick) => new Date(tick).toLocaleDateString()} />
              <YAxis tickFormatter={numberFormatter} allowDataOverflow padding={{ top: 20, bottom: 20 }} />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="facebook" stroke="#3b5998" />
              <Line type="monotone" dataKey="tiktok" stroke="#69C9D0" />
              <Line type="monotone" dataKey="youtube" stroke="#FF0000" />
              <Line type="monotone" dataKey="instagram" stroke="#C13584" />
              <Line type="monotone" dataKey="repost" stroke="#4CAF50" />
            </LineChart>
          </ResponsiveContainer>
        </Box>

        {/* Render the DataGrid for platform views, likes, shares, comments, and saves */}
        <Box sx={{ mb: 5 }}>
          <Typography variant="h6">Platform Data</Typography>
          <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={platformData} // Use the aggregated data fetched from your backend
              columns={[
                { field: 'platform', headerName: 'Platform', width: 150 },
                { field: 'views_total', headerName: 'Views', width: 100 },
                { field: 'likes_total', headerName: 'Likes', width: 100 },
                { field: 'shares_total', headerName: 'Shares', width: 100 },
                { field: 'comments_total', headerName: 'Comments', width: 100 },
                { field: 'saves_total', headerName: 'Saves', width: 100 },
              ]}
              pagination
              rowsPerPageOptions={[10, 25, 50]}
              loading={loading}
              disableRowSelectionOnClick
              components={{ Toolbar: GridToolbar }}
              getRowId={(row) => `${row.platform}-${row.created}`} // Unique combination for ID
            />
          </Box>
        </Box>
      </Box>
   
  );
};

export default AdminAnalytics;

