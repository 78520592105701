import React from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTable, faChartLine, faSignOutAlt, faChalkboard,faListCheck,faClock,faBarsProgress,faGear,faCheckToSlot } from '@fortawesome/free-solid-svg-icons';
import './Sidebar.css';

const AdminSidebar = ({ setCurrentSection }) => {
  const handleSectionChange = (section) => {
    setCurrentSection(section);
  };

  const { setIsAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('username');
    setIsAuthenticated(false);
    navigate('/');
    window.history.replaceState(null, null, '/'); // Clears history stack
  };
  return (
    <div className="sidebar">
      <ul className="nav flex-column">
      <li className="nav-item">
          <NavLink to="/admindashboard" className="nav-link"  onClick={() => handleSectionChange('Video Details')} >
            <FontAwesomeIcon icon={faTable} className="fa"/>
           
            <span className="link-text">Video Details</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/dashboard" className="nav-link" onClick={() => handleSectionChange('Dashboard')}>
            <FontAwesomeIcon icon={faChartLine} className="fa" />
            <span className="link-text">Dashboard</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/sponsoredvideoentry" className="nav-link"  onClick={() => handleSectionChange(' Sponsored Video Entry')}>
            <FontAwesomeIcon icon={faListCheck} className="fa" />
            <span className="link-text">Sponsored Video Entry</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/nonsponsoredvideoentry" className="nav-link"  onClick={() => handleSectionChange(' NonSponsored Video Entry')}>
            <FontAwesomeIcon icon={faChalkboard} className="fa" />
            <span className="link-text">Non Sponsored Video Entry</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/pendingwriters" className="nav-link"  onClick={() => handleSectionChange('Pending Accounts')}>
            <FontAwesomeIcon icon={faClock} className="fa" />
            <span className="link-text">Pending Accounts</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/repost" className="nav-link"  onClick={() => handleSectionChange('Repost Entries')}>
            <FontAwesomeIcon icon={faCheckToSlot} className="fa" />
            <span className="link-text">Repost Entries</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/managewriters" className="nav-link"  onClick={() => handleSectionChange('Video Entry')}>
            <FontAwesomeIcon icon={faBarsProgress} className="fa" />
            <span className="link-text">Manage Writers</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/settings" className="nav-link"  onClick={() => handleSectionChange('Video Entry')}>
            <FontAwesomeIcon icon={faGear} className="fa" />
            <span className="link-text">Settings</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/" className="nav-link" onClick={handleLogout}>
            <FontAwesomeIcon icon={faSignOutAlt} className="fa" />
            <span className="link-text">Logout</span>
          </NavLink>
        </li>
        
      </ul>
    </div>
  );
};

export default AdminSidebar;
