// src/components/LineChartLikesComponent.js
import React from 'react';
import ReactECharts from 'echarts-for-react';
import dayjs from 'dayjs';

const LineChartLikesComponent = ({ data }) => {
  if (!data || data.length === 0) {
    return <p>No overall likes data available</p>;
  }

  // Format data to show date and time
  const formattedData = data.map(item => ({
    time: dayjs(item._time).format('MMM D, HH:mm'),
    value: item._value,
  }));

  const options = {
    xAxis: {
      type: 'category',
      data: formattedData.map(item => item.time),
      boundaryGap: false,
      axisLine: { lineStyle: { color: '#e0e0e0' } },
      axisLabel: {
        color: '#8c8c8c',
        fontSize: 12,
      },
    },
    yAxis: {
      type: 'value',
      axisLine: { lineStyle: { color: '#e0e0e0' } },
      axisLabel: {
        color: '#8c8c8c',
        fontSize: 12,
        formatter: (value) => {
          if (value >= 1e6) return `${(value / 1e6).toFixed(1)}M`;
          if (value >= 1e3) return `${(value / 1e3).toFixed(1)}K`;
          return value.toLocaleString();
        },
      },
      splitLine: { lineStyle: { color: '#f0f0f0' } },
    },
    series: [
      {
        data: formattedData.map(item => item.value),
        type: 'line',
        smooth: true,
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              { offset: 0, color: 'rgba(244, 81, 30, 0.4)' }, // Start with orange at the top
              { offset: 1, color: 'rgba(244, 81, 30, 0)' },   // Fade to transparent at the bottom
            ],
          },
        },
        lineStyle: { color: '#f4511e', width: 2 }, // Consistent orange line color
        symbol: 'circle',
        symbolSize: 6,
      },
    ],
    tooltip: {
      trigger: 'axis',
      backgroundColor: '#ffffff',
      borderColor: '#ccc',
      borderWidth: 1,
      textStyle: { color: '#666' },
      formatter: (params) => {
        const date = params[0]?.name || 'N/A';
        const value = params[0]?.value || 0;
        return `
          <div style="min-width: 225px;">
            <div style="font-size: 12px; color: #747575;">${date}</div>
            <div style="font-size: 18px; font-weight: 600; color: #000;">Likes: ${value.toLocaleString()}</div>
          </div>
        `;
      },
      extraCssText: 'box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);',
    },
    grid: { 
      left: '3%', 
      right: '3%', 
      bottom: '3%', 
      top: '10%', 
      containLabel: true 
    },
  };

  return <ReactECharts option={options} style={{ height: '400px', width: '100%' }} />;
};

export default LineChartLikesComponent;
