import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faHeart, faComment } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";

// Function to format the timestamp to a readable format
function formatTimestamp(timestamp) {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  return new Date(timestamp).toLocaleString(undefined, options);
}

const VideoCard = ({ video }) => {
  const [isPreviewAvailable, setIsPreviewAvailable] = useState(true); // State to track preview availability
  const previewUrl = video.preview || null; // Set preview URL if available, otherwise null

  const handleImageError = () => {
    setIsPreviewAvailable(false); // If image fails to load, set preview to unavailable
  };

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      width: "200px",
      backgroundColor: "#fff",
      borderRadius: "10px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    }}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr",
          gridTemplateRows: "1fr",
          borderRadius: "10px",
          overflow: "hidden",
          position: "relative",
          width: "100%",
          height: "350px",
        }}
      >
        {isPreviewAvailable && previewUrl ? (
          <img
            src={previewUrl}
            alt="Video preview"
            style={{
              gridArea: "1/1",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              
            }}
            onError={handleImageError} // Call handleImageError if image fails to load
          />
        ) : (
          <div
            style={{
              gridArea: "1/1",
              width: "100%",
              height: "100%",
              backgroundColor: "#000",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "red",
              fontWeight: "bold",
              fontSize: "15px",
              textTransform: "uppercase",
            }}
          >
            Video Unavailable
          </div>
        )}

        <div
          style={{
            gridArea: "1/1",
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            color: "white",
            background: "linear-gradient(0deg, rgba(0,0,0,0.9) 10%, rgba(0,0,0,0.85) 30%, rgba(0,0,0,0) 100%)",
            zIndex: 1, // Make sure this overlay is always on top of the image
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <FontAwesomeIcon icon={faEye} style={{ fontSize: "small" }} />
            {video.views_total || "N/A"}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <FontAwesomeIcon icon={faHeart} style={{ fontSize: "small" }} />
            {video.likes_total || "N/A"}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <FontAwesomeIcon icon={faComment} style={{ fontSize: "small" }} />
            {video.comments_total || "N/A"}
          </div>
        </div>
      </div>

      <div style={{ padding: "10px", textAlign: "center" }}>
        <span style={{ fontWeight: "bold", fontSize: "14px", display: "block" }}>
          {video.username || "No User"}
        </span>
        <span style={{ fontSize: "12px", color: "#666" }}>
          {video.name || "No Sponsor"}
        </span>
        <span style={{ fontSize: "12px", color: "#666", display: "block" }}>
          {new Date(video.created).toLocaleDateString()}
        </span>
        <Button href={`/videos/${video.video_id}`} style={{ width: '100%' }} variant="primary">
          Details
        </Button>
        <Button
          href={video.url}
          variant="secondary"
          target="_blank"
          rel="noopener noreferrer"
          style={{ width: '100%' }}
        >
          View on Platform
        </Button>
      </div>
    </div>
  );
};

export default VideoCard;
