import React from "react";
import { useNavigate,Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Button,
  
  
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { styled } from "@mui/system";
import { FaUserCircle, FaRegUser } from "react-icons/fa";

// Create a custom MUI theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "25px",
          padding: "10px 25px",
          textTransform: "none",
          fontWeight: "bold",
        },
      },
    },
  },
});

// Styled components for the top bar and hero section
const StyledAppBar = styled(AppBar)({
  backgroundColor: "#ff6d00",
  boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.3)', // Adds a stronger shadow on hover
  padding: "10px",


  
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height:'70px'
});

const HeroSection = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "85vh",
  background: "#fff",
  color: "#fff",
  textAlign: "center",
  padding: "0 30px",
  position: "relative",
  zIndex: 0,
  overflow: "hidden",
});

const BackgroundOverlay = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background:'#fff',
  zIndex: -1,
});

const Welcome = () => {
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ minHeight: "100vh", backgroundColor: "#fff" }}>
        {/* Continuous Top Bar */}
        <StyledAppBar position="static">
          <Toolbar sx={{ justifyContent: "space-between", width: "100%" }}>
            {/* Plotpointe logo/text */}
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                fontFamily: "'Poppins', sans-serif",
                cursor: "pointer",
                color: "#fff",
              }}
              onClick={() => navigate("/")}
            >
              Plotpointe
            </Typography>

            {/* Navigation Items */}
            <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
              {/* Sign In and Sign Up buttons */}
              <Button
                variant="text"
                color="primary"
                startIcon={<FaUserCircle />}
                onClick={() => navigate("/login")}
                sx={{ color: "#fff" }}
              >
                Log In
              </Button>
              <Button
                variant="contained"
                startIcon={<FaRegUser />}
                onClick={() => navigate("/register")}
                sx={{
                  backgroundColor: "#fff",
                  color: "#ff6d00",
                  "&:hover": {
                    backgroundColor: "#ff6d00",
                    color: "#fff",
                  },
                }}
              >
                Sign Up
              </Button>
            </Box>
          </Toolbar>
        </StyledAppBar>

        {/* Hero Section */}
        <HeroSection>
          <BackgroundOverlay />
          <Typography
            variant="h3"
            sx={{
              fontWeight: 700,
              mb: 2,
              fontFamily: "'Poppins', sans-serif",
              textShadow: "2px 2px 4px rgba(0,0,0,0.4)",
              color:'#000'
            }}
          >
            Plotpointe Analytics <br />
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mb: 4,
              maxWidth: "600px",
              fontSize: "18px",
              color: "#ffd3b6",
            }}
          ></Typography>

          {/* Buttons */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 4,
            }}
          >
            <Button
              component={Link} // Makes the button a link
              to="/login" // Sets the destination path
              variant="contained"
              size="large"
              sx={{
                padding: "12px 24px",
                fontSize: "18px",
                color: "#ff6d00",
                backgroundColor: "#fff",
                border: "2px solid #fff",
                borderRadius: "25px",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "#ffe5d0",
                  color: "#ff6d00",
                },
              }}
            >
              Sign in
            </Button>
          </Box>
        </HeroSection>
      </Box>
    </ThemeProvider>
  );
};

export default Welcome;
