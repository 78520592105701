import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Alert,
  Stack,
  Typography,
  MenuItem, Select, FormControl
} from "@mui/material";
import { DataGrid, useGridApiRef, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

const RepostEntries = () => {
  const [entries, setEntries] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [pageSize, setPageSize] = useState(10); // Page size
  const [sponsors, setSponsors] = useState([]); // Add sponsors state
  const navigate = useNavigate();

  useEffect(() => {
    fetchEntries();
    fetchSponsors(); // Fetch sponsors list
  }, []);

  const fetchSponsors = async () => {
    try {
      const response = await axios.get("/api/sponsors");
      setSponsors(response.data); // Set sponsors data
    } catch (error) {
      setError("Error fetching sponsors");
    }
  };


  const handleSponsorChange = async (id, sponsorId) => {
    try {
      await axios.post("/api/repostEntries/updateSponsor", { id, sponsorId });
      setSuccess("Sponsor updated successfully");
      fetchEntries();
    } catch (error) {
      setError("Error updating sponsor");
    }
  };

  const fetchEntries = async () => {
    try {
      const response = await axios.get("/api/repostEntries");
      setEntries(response.data);
    } catch (error) {
      setError("Error fetching repost entries");
    }
  };

  const handleDecision = async (id, decision) => {
    try {
      const response = await axios.post("/api/repostEntries/decision", {
        id,
        decision,
      });
      setSuccess(response.data.message);
      fetchEntries();
    } catch (error) {
      setError("Error processing the decision");
    }
  };

  const handleFetchRepostUrls = async () => {
    try {
      const existingUrls = entries.map((entry) => entry.url);
      const response = await axios.post("/api/fetchAndStoreReposts", {
        existingUrls,
      });
      setSuccess(response.data.message);
      fetchEntries();
    } catch (error) {
      setError("Error fetching new repost URLs");
    }
  };

  const handleOriginalUrlClick = (videoId) => {
    navigate(`/videos/${videoId}`);
  };

  const columns = [
    { field: "id", headerName: "#", width: 50, sortable: false },
    {
      field: "original_url",
      headerName: "Original URL",
      width: 220,
      renderCell: (params) => (
        <Button
        component="a"
        href={params.row.video_id ? `/videos/${params.row.video_id}` : "#"}
        target="_blank"
        rel="noopener noreferrer"
        sx={{ textTransform: "none", fontSize: "0.8rem" }}
      >
        {params.value || "N/A"}
      </Button>
      
      ),
    },
    {
      field: "url",
      headerName: "Repost URL",
      width: 220,
      renderCell: (params) => (
        <Button
          href={params.value}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ textTransform: "none", fontSize: "0.8rem" }}
        >
          {params.value}
        </Button>
      ),
    },
    { field: "views", headerName: "Views", width: 150, align: "center" },
    { field: "subtitle_binary", headerName: "Subtitle", width: 200 },
    { field: "upload_time", headerName: "Upload Time", width: 150, align: "center" },
     {
    field: "sponsor",
    headerName: "Sponsor",
    width: 150,
    renderCell: (params) => (
      <FormControl fullWidth>
        <Select
          value={params.row.sponsor_id || ""}
          onChange={(e) => handleSponsorChange(params.row.id, e.target.value)}
          displayEmpty
          size="small"
        >
          <MenuItem value="">
            <p>Select Sponsor</p>
          </MenuItem>
          {sponsors.map((sponsor) => (
            <MenuItem key={sponsor.id} value={sponsor.id}>
              {sponsor.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ),
  },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      align: "center",
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          {params.row.status === "pending" ? (
            <>
              <Button
                size="small"
                variant="contained"
                color="success"
                onClick={() => handleDecision(params.row.id, "accepted")}
                sx={{ fontSize: "0.7rem", padding: "3px 6px" }}
              >
                Accept
              </Button>
              <Button
                size="small"
                variant="contained"
                color="error"
                onClick={() => handleDecision(params.row.id, "rejected")}
                sx={{ fontSize: "0.7rem", padding: "3px 6px" }}
              >
                Reject
              </Button>
            </>
          ) : (
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={() => handleDecision(params.row.id, "pending")}
              sx={{ fontSize: "0.7rem", padding: "3px 6px" }}
            >
              Undo
            </Button>
          )}
        </Stack>
      ),
    },
  ];

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Repost Entries Management
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {success}
        </Alert>
      )}

    

      <Box sx={{ height: 600, width: "1270px" }}>
        <DataGrid
          rows={entries}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20]}
          pagination
          disableSelectionOnClick
          disableColumnMenu
          rowHeight={40}
          headerHeight={40}
          slots={{ toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default RepostEntries;

