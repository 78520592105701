import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Container,
  Grid,
} from '@mui/material';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';

const SponsoredVideoEntry = () => {
  const [sponsors, setSponsors] = useState([]);
  const [writers, setWriters] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedSponsor, setSelectedSponsor] = useState('');
  const [selectedWriter, setSelectedWriter] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const sponsorResponse = await axios.get('/api/sponsors');
        setSponsors(sponsorResponse.data);
        const writerResponse = await axios.get('/api/writers');
        setWriters(writerResponse.data);
        const userResponse = await axios.get('/api/users');
        setUsers(userResponse.data);
      } catch (error) {
        setError('Error fetching data');
      }
    };
    fetchOptions();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      const response = await axios.post('/api/videos', {
        sponsor_name: selectedSponsor,
        writer_name: selectedWriter,
        user_name: selectedUser,
        url,
        video_type: 'Sponsored',
      });
      if (response.data.success) {
        setSuccess('Video entry added successfully!');
        setUrl('');
      } else {
        setError('URL already exists');
      }
    } catch (error) {
      setError('Error submitting the video entry');
    }
  };

  const handleCsvOrExcelUpload = async (e) => {
    setError('');
    setSuccess('');
    const file = e.target.files[0];

    if (!file) return;

    const fileExtension = file.name.split('.').pop();
    if (fileExtension === 'csv') {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: async (results) => {
          await processFileData(results.data);
        },
      });
    } else if (fileExtension === 'xlsx') {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        await processFileData(jsonData);
      };
      reader.readAsBinaryString(file);
    } else {
      setError('Unsupported file type. Please upload a CSV or Excel file.');
    }
  };

  const processFileData = async (data) => {
    try {
      const response = await axios.post('/api/videos/bulk', { data, video_type: 'Non-Sponsored' });
      if (response.data.success) {
        setSuccess('File data processed and video entries added successfully!');
      } else {
        setError('Error processing file data');
      }
    } catch (error) {
      setError('Error uploading the file data');
    }
  };

  return (
    <Box sx={{ p: 3,alignItems:'center',marginLeft:'380px',marginTop:'50px' }}>
      <Container maxWidth="sm">
        <Typography variant="h4" gutterBottom>
          Video Entry
        </Typography>

        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}

        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Sponsor Name</InputLabel>
                <Select
                  value={selectedSponsor}
                  onChange={(e) => setSelectedSponsor(e.target.value)}
                  label="Sponsor Name"
                  required
                >
                  <MenuItem value="">
                    <em>Select Sponsor</em>
                  </MenuItem>
                  {sponsors.map((sponsor) => (
                    <MenuItem key={sponsor.id} value={sponsor.name}>
                      {sponsor.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Writer Name</InputLabel>
                <Select
                  value={selectedWriter}
                  onChange={(e) => setSelectedWriter(e.target.value)}
                  label="Writer Name"
                  required
                >
                  <MenuItem value="">
                    <em>Select Writer</em>
                  </MenuItem>
                  {writers.map((writer) => (
                    <MenuItem key={writer.id} value={writer.name}>
                      {writer.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>User Name</InputLabel>
                <Select
                  value={selectedUser}
                  onChange={(e) => setSelectedUser(e.target.value)}
                  label="User Name"
                  required
                >
                  <MenuItem value="">
                    <em>Select User</em>
                  </MenuItem>
                  {users.map((user) => (
                    <MenuItem key={user.id} value={user.username}>
                      {user.username}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="URL"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                type="url"
                required
              />
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>

        <Box sx={{ mt: 4 }}>
          <Typography variant="h6">Upload CSV or Excel for Non-Sponsored Videos</Typography>
          <Button
            variant="contained"
            component="label"
            sx={{ mt: 1 }}
            fullWidth
          >
            Upload File
            <input
              type="file"
              accept=".csv,.xlsx"
              hidden
              onChange={handleCsvOrExcelUpload}
            />
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default SponsoredVideoEntry;
