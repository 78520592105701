import React, { useState, useEffect } from "react";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CreateIcon from "@mui/icons-material/Create";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAuth } from "../context/AuthContext";
import axios from "axios";

// Constants for drawer width
const drawerWidthOpen = 240;
const drawerWidthClosed = 56;

// Sidebar Component
const WriterSidebar = ({ isDrawerOpen }) => {
  const { setIsAuthenticated } = useAuth();
  const navigate = useNavigate();
  const username = localStorage.getItem("username");

  // Handle logout
  const handleLogout = () => {
    localStorage.clear();
    setIsAuthenticated(false);
    navigate("/login");
  };

  // Helper function to check if a route is active
  const isActive = (path) => window.location.pathname.includes(path);

  return (
    <Drawer
      sx={{
        width: isDrawerOpen ? drawerWidthOpen : drawerWidthClosed,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: isDrawerOpen ? drawerWidthOpen : drawerWidthClosed,
          boxSizing: "border-box",
          transition: "width 0.3s",
          top: 65,
          position: "fixed",
          height: "100%",
          overflow: "hidden",
        },
      }}
      variant="permanent"
      open={isDrawerOpen}
    >
      <List>
        {/* Script Submit */}
        <ListItem
          button
          component={Link}
          to="/writerdashboard"
          selected={isActive("/writerdashboard")}
        >
          <ListItemIcon
            sx={{ color: isActive("/writerdashboard") ? "#fb8c00" : "#000" }}
          >
            <CreateIcon />
          </ListItemIcon>
          {isDrawerOpen && <ListItemText primary="Script Submit" />}
        </ListItem>

        {/* Analytics */}
        <ListItem
          button
          component={Link}
          to="/writeranalytics"
          selected={isActive("/writeranalytics")}
        >
          <ListItemIcon
            sx={{ color: isActive("/writeranalytics") ? "#fb8c00" : "#000" }}
          >
            <AnalyticsIcon />
          </ListItemIcon>
          {isDrawerOpen && <ListItemText primary="Analytics" />}
        </ListItem>

        {/* Logout */}
        <ListItem button onClick={handleLogout}>
          <ListItemIcon sx={{ color: "#000" }}>
            <LogoutIcon />
          </ListItemIcon>
          {isDrawerOpen && <ListItemText primary="Logout" />}
        </ListItem>
      </List>
    </Drawer>
  );
};

// TopBar Component
const TopBar = ({ toggleDrawer }) => {
  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: "#ffffff",
        boxShadow: "2px",
      }}
    >
      <Toolbar>
        {/* Hamburger Menu Icon */}
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={toggleDrawer}
        >
          <MenuIcon sx={{ color: "#ef6c00" }} />
        </IconButton>

        {/* Title */}
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{
            flexGrow: 1,
            color: "#ef6c00",
            fontWeight: "bold",
            marginLeft: "20px",
          }}
        >
          Plotpointe Writer Dashboard
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

// WriterLayout Component
const WriterLayout = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(true);
  const location = useLocation(); // Get the current location

  // Toggle drawer state
  const toggleDrawer = () => {
    setDrawerOpen((prev) => !prev);
  };

  // Automatically close the drawer when the URL changes
  useEffect(() => {
    setDrawerOpen(false);
  }, [location]);

  return (
    <Box sx={{ display: "flex" }}>
      {/* Top navigation */}
      <TopBar toggleDrawer={toggleDrawer} />

      {/* Left-side Writer Sidebar */}
      <WriterSidebar
        isDrawerOpen={isDrawerOpen}
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "#ffffff",
          boxShadow: "2px",
        }}
      />

      {/* Main content area */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          transition: 'margin 0.3s',
          marginLeft: `${drawerWidthClosed}px`, // Adjust for the collapsed sidebar
          marginTop: 5,
          position: 'absolute',
          scrollBehavior:'auto',
          backgroundColor:'#ffffff'
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default WriterLayout;
