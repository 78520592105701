import React from 'react';
import { Box, Typography } from '@mui/material';

const WriterPayouts = () => {
  return (
    <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Typography variant="h4" sx={{ color: '#000' }}>
        Writer Payouts
      </Typography>
    </Box>
  );
};

export default WriterPayouts;
