import React from 'react';

import {  Outlet} from 'react-router-dom';

function VideoEntryTabs() {

  return (
   
     
      <Outlet />
   
  );
}

export default VideoEntryTabs;
