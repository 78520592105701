import React from 'react';

import {  Outlet} from 'react-router-dom';

function VideoDetailsTabs() {

  return (
   
     
      <Outlet />
   
  );
}

export default VideoDetailsTabs;