import React, { useState,useEffect } from 'react';
import { Link, useLocation, Outlet,useNavigate } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  InputBase,
  Box,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ReplayIcon from '@mui/icons-material/Replay';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import MovieIcon from '@mui/icons-material/Movie';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import TiktokIcon from '@mui/icons-material/MusicVideo';

// Constants for drawer width
const drawerWidthOpen = 240;
const drawerWidthClosed = 56;

const LeftSideNavbar = ({ isDrawerOpen }) => {
  const [openVideoManagement, setOpenVideoManagement] = useState(false);
  const [openUserManagement, setOpenUserManagement] = useState(false);
  const [openRepostManagement, setOpenRepostManagement] = useState(false);
  const [openAnalytics, setOpenAnalytics] = useState(false);
  const [openVideoDetails, setOpenVideoDetails] = useState(false);
  const [openVideoEntry, setOpenVideoEntry] = useState(false);
  const location = useLocation();

  const toggleMenu = (setMenuOpen) => () => setMenuOpen((prev) => !prev);


  // Automatically close all submenus when the drawer collapses
  useEffect(() => {
    if (!isDrawerOpen) {
      setOpenVideoManagement(false);
      setOpenUserManagement(false);
      setOpenRepostManagement(false);
      setOpenAnalytics(false);
      setOpenVideoDetails(false);
      setOpenVideoEntry(false);
    }
  }, [isDrawerOpen]);

  // Helper function to check if a route is active
  const isActive = (path) => location.pathname.includes(path);

  return (
    <Drawer
      sx={{
        width: isDrawerOpen ? drawerWidthOpen : drawerWidthClosed,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: isDrawerOpen ? drawerWidthOpen : drawerWidthClosed,
          boxSizing: 'border-box',
          transition: 'width 0.3s',
          top: 65,
          position: 'fixed',
          height: '100%',
          overflow: 'hidden',
        },
      }}
      variant="permanent"
      open={isDrawerOpen}
    >
      <List>
        {/* Video Management */}
        <ListItem button onClick={toggleMenu(setOpenVideoManagement)}>
          <ListItemIcon sx={{ color: isActive('/video-details') ? '#fb8c00' : 'inherit' }}>
            <VideoLibraryIcon />
          </ListItemIcon>
          {isDrawerOpen && <ListItemText primary="Video Management" />}
          {openVideoManagement ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={openVideoManagement} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button onClick={toggleMenu(setOpenVideoDetails)}>
              <ListItemIcon>
                <MovieIcon />
              </ListItemIcon>
              <ListItemText inset primary="Video Details" />
              {openVideoDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={openVideoDetails} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button component={Link} to="/video-details/tiktok" selected={isActive('/video-details/tiktok')}>
                  <ListItemIcon>
                    <TiktokIcon />
                  </ListItemIcon>
                  <ListItemText inset primary="TikTok" sx={{ color: isActive('/video-details/tiktok') ? '#fb8c00' : 'inherit' }} />
                </ListItem>
                <ListItem button component={Link} to="/video-details/facebook" selected={isActive('/video-details/facebook')}>
                  <ListItemIcon>
                    <FacebookIcon />
                  </ListItemIcon>
                  <ListItemText inset primary="Facebook" sx={{ color: isActive('/video-details/facebook') ? '#fb8c00' : 'inherit' }} />
                </ListItem>
                <ListItem button component={Link} to="/video-details/youtube" selected={isActive('/video-details/youtube')}>
                  <ListItemIcon>
                    <YouTubeIcon />
                  </ListItemIcon>
                  <ListItemText inset primary="YouTube" sx={{ color: isActive('/video-details/youtube') ? '#fb8c00' : 'inherit' }} />
                </ListItem>
                <ListItem button component={Link} to="/video-details/instagram" selected={isActive('/video-details/instagram')}>
                  <ListItemIcon>
                    <InstagramIcon />
                  </ListItemIcon>
                  <ListItemText inset primary="Instagram" sx={{ color: isActive('/video-details/instagram') ? '#fb8c00' : 'inherit' }} />
                </ListItem>
                <ListItem button component={Link} to="/video-details/repost" selected={isActive('/video-details/repost')}>
                  <ListItemIcon>
                    <ReplayIcon />
                  </ListItemIcon>
                  <ListItemText inset primary="Repost" sx={{ color: isActive('/video-details/repost') ? '#fb8c00' : 'inherit' }} />
                </ListItem>
              </List>
            </Collapse>

            <ListItem button onClick={toggleMenu(setOpenVideoEntry)}>
              <ListItemIcon>
                <VideoCallIcon />
              </ListItemIcon>
              <ListItemText inset primary="Video Entry" />
              {openVideoEntry ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={openVideoEntry} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button component={Link} to="/video-entry/sponsoredvideoentry" selected={isActive('/video-entry/sponsoredvideoentry')}>
                  <ListItemIcon>
                    <MonetizationOnIcon />
                  </ListItemIcon>
                  <ListItemText inset primary="Sponsored Video Entry" sx={{ color: isActive('/video-entry/sponsoredvideoentry') ? '#fb8c00' : 'inherit' }} />
                </ListItem>
                <ListItem button component={Link} to="/video-entry/nonsponsoredvideoentry" selected={isActive('/video-entry/nonsponsoredvideoentry')}>
                  <ListItemIcon>
                    <VideoLabelIcon />
                  </ListItemIcon>
                  <ListItemText inset primary="Non-Sponsored Video Entry" sx={{ color: isActive('/video-entry/nonsponsoredvideoentry') ? '#fb8c00' : 'inherit' }} />
                </ListItem>
              </List>
            </Collapse>
          </List>
        </Collapse>

        {/* User Management */}
        <ListItem button onClick={toggleMenu(setOpenUserManagement)}>
          <ListItemIcon sx={{ color: isActive('/pendingwriters') ? '#fb8c00' : 'inherit' }}>
            <AccountCircleIcon />
          </ListItemIcon>
          {isDrawerOpen && <ListItemText primary="User Management" />}
          {openUserManagement ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={openUserManagement} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={Link} to="/pendingwriters" selected={isActive('/pendingwriters')}>
              <ListItemText inset primary="Pending Accounts" sx={{ color: isActive('/pendingwriters') ? '#fb8c00' : 'inherit' }} />
            </ListItem>
            <ListItem button component={Link} to="/managewriters" selected={isActive('/managewriters')}>
              <ListItemText inset primary="Manage Writers" sx={{ color: isActive('/managewriters') ? '#fb8c00' : 'inherit' }} />
            </ListItem>
          </List>
        </Collapse>

        {/* Repost Management */}
        <ListItem button onClick={toggleMenu(setOpenRepostManagement)}>
          <ListItemIcon sx={{ color: isActive('/repost') ? '#fb8c00' : 'inherit' }}>
            <ReplayIcon />
          </ListItemIcon>
          {isDrawerOpen && <ListItemText primary="Repost Management" />}
          {openRepostManagement ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={openRepostManagement} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={Link} to="/repost" selected={isActive('/repost')}>
              <ListItemText inset primary="Repost Entries" sx={{ color: isActive('/repost') ? '#fb8c00' : 'inherit' }} />
            </ListItem>
          </List>
        </Collapse>

        {/* Analytics */}
          <ListItem button component={Link} to="/analytics" selected={isActive('/analytics')}>
            <ListItemIcon sx={{ color: isActive('/analytics') ? '#fb8c00' : '#000' }}>
              <AnalyticsIcon />
            </ListItemIcon>
            {isDrawerOpen && <ListItemText primary="Analytics" />}
          </ListItem>
        

          {/* Settings */}
          <ListItem button component={Link} to="/settings" selected={isActive('/settings')}>
            <ListItemIcon sx={{ color: isActive('/settings') ? '#fb8c00' : '#000' }}>
              <SettingsIcon />
            </ListItemIcon>
            {isDrawerOpen && <ListItemText primary="Settings" />}
          </ListItem>

          {/* Logout */}
          <ListItem button component={Link} to="/logout" selected={isActive('/logout')}>
            <ListItemIcon sx={{ color: isActive('/logout') ? '#fb8c00' : '#000' }}>
              <LogoutIcon />
            </ListItemIcon>
            {isDrawerOpen && <ListItemText primary="Logout" />}
          </ListItem>
      </List>
    </Drawer>
  );
};
// Map of menu titles to their corresponding URLs
const menuLinks = {
  "Video Details": "/video-details",
  "TikTok": "/video-details/tiktok",
  "Facebook": "/video-details/facebook",
  "YouTube": "/video-details/youtube",
  "Instagram": "/video-details/instagram",
  "Repost": "/video-details/repost",
  "Video Entry": "/video-entry",
  "Sponsored Video Entry": "/video-entry/sponsoredvideoentry",
  "Non-Sponsored Video Entry": "/video-entry/nonsponsoredvideoentry",
  "User Management": "/pendingwriters",
  "Pending Accounts": "/pendingwriters",
  "Manage Writers": "/manage-writers",
  "Repost Entries": "/repost",
  "Analytics": "/analytics",
  "Overview": "/overview",
  "Dashboard": "/dashboard",
  "Settings": "/settings",
  "Logout": "/logout",
};


const TopBar = ({ toggleDrawer }) => {
  // State to hold the search input
  const [searchQuery, setSearchQuery] = useState("");

  // React Router's useNavigate hook for navigation
  const navigate = useNavigate();

  // Handle input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Handle search submission
  const handleSearchSubmit = (event) => {
    event.preventDefault();

    // Check if the typed search query matches a sidebar title
    const matchingLink = Object.keys(menuLinks).find(
      (title) => title.toLowerCase() === searchQuery.toLowerCase()
    );

    if (matchingLink) {
      navigate(menuLinks[matchingLink]); // Redirect to the matching link
    } else {
      alert("No matching menu item found."); // Show an alert if no match found
    }
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: "#ffffff",
        boxShadow: "2px",
      }}
    >
      <Toolbar>
        {/* Hamburger Menu Icon */}
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={toggleDrawer}
        >
          <MenuIcon sx={{ color: "#ef6c00" }} />
        </IconButton>

        {/* Title */}
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{
            flexGrow: 1,
            color: "#ef6c00",
            fontWeight: "bold",
            marginLeft: "20px",
          }}
        >
          Plotpointe
        </Typography>

        {/* Search Bar */}
        <Box
          component="form"
          onSubmit={handleSearchSubmit}
          sx={{
            position: "relative",
            marginLeft: "auto",
            display: "flex",
            alignItems: "center",
            background: "#f1f3f4",
            borderRadius: 2,
            padding: "4px 10px",
          }}
        >
          <SearchIcon sx={{ color: "#333" }} />
          <InputBase
            placeholder="Search menu…"
            value={searchQuery}
            onChange={handleSearchChange}
            sx={{
              ml: 1,
              width: "200px",
              color: "#333",
            }}
            inputProps={{ "aria-label": "search" }}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const Layout = ({ children }) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  return (
    <Box sx={{ display: 'flex' }}>
      {/* Top navigation */}
      <TopBar toggleDrawer={() => setDrawerOpen((prev) => !prev)} />

      {/* Left-side navigation */}
      <LeftSideNavbar isDrawerOpen={isDrawerOpen} />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          transition: 'margin 0.3s',
          marginLeft: `${drawerWidthClosed}px`, // Adjust for the collapsed sidebar
          marginTop: 5,
          position: 'absolute',
          scrollBehavior:'auto',
          backgroundColor:'#ffffff'
        }}
      >
        <Outlet />  {/* This renders the routed content */}
      </Box>
    </Box>
  );
};

export default Layout;
