import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Button,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Container,
  Grid,
} from '@mui/material';

const NonSponsoredVideoEntry = () => {
  const [writers, setWriters] = useState([]);
  const [selectedWriter, setSelectedWriter] = useState('');
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [currentSection] = useState('Admin Dashboard');

  useEffect(() => {
    const fetchWriters = async () => {
      try {
        const writerResponse = await axios.get('/api/writers');
        setWriters(writerResponse.data);
      } catch (error) {
        setError('Error fetching writers data');
      }
    };
    fetchWriters();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      const response = await axios.post('/api/nonsponsored', {
        writer_name: selectedWriter,
        url,
      });

      if (response.data.success) {
        setSuccess('Non-sponsored video entry added successfully!');
        setUrl('');
        setSelectedWriter('');
      } else {
        setError(response.data.message); // This will catch the "URL already exists" message
      }
    } catch (error) {
      console.error('Error submitting the video entry:', error);
      if (error.response && error.response.status === 409) {
        setError('This URL already exists in the database.');
      } else {
        setError('Error submitting the video entry. Please try again later.');
      }
    }
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3,marginLeft:'380px',marginTop:'50px' }}>
      <Container maxWidth="sm">
        <Typography variant="h4" gutterBottom>
          Non-Sponsored Video Entry
        </Typography>

        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}

        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Writer Name</InputLabel>
                <Select
                  value={selectedWriter}
                  onChange={(e) => setSelectedWriter(e.target.value)}
                  label="Writer Name"
                  required
                >
                  <MenuItem value="">
                    <em>Select Writer</em>
                  </MenuItem>
                  {writers.map((writer) => (
                    <MenuItem key={writer.id} value={writer.name}>
                      {writer.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="URL"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                type="url"
                required
              />
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};

export default NonSponsoredVideoEntry;

