import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import '../styles.css';

const TrelloDataForm = ({ apiKey, token, listId, onSaveListId }) => {
    const [workspaces, setWorkspaces] = useState([]);
    const [selectedList, setSelectedList] = useState(listId || '');
    const [error, setError] = useState('');

    const fetchWorkspaces = useCallback(async () => {
        try {
            const response = await axios.get(
                `https://api.trello.com/1/members/me/organizations?key=${apiKey}&token=${token}`
            );
            setWorkspaces(response.data);
        } catch (error) {
            console.error('Error fetching workspaces:', error);
            setError('Error fetching workspaces');
        }
    }, [apiKey, token]);

    useEffect(() => {
        if (apiKey && token) {
            fetchWorkspaces();
        }
    }, [apiKey, token, fetchWorkspaces]);

    const fetchLists = async (boardId) => {
        try {
            const response = await axios.get(
                `https://api.trello.com/1/boards/${boardId}/lists?key=${apiKey}&token=${token}`
            );
            setSelectedList(response.data[0]?.id || ''); // Assume first list as target list
            onSaveListId(response.data[0]?.id || ''); // Save selected list ID
        } catch (error) {
            console.error('Error fetching lists:', error);
            setError('Error fetching lists');
        }
    };

    return (
        <div>
            <button
                className="fetch-workspaces-button"
                onClick={fetchWorkspaces}
            >
                Fetch Workspaces
            </button>
            <div className="workspace-list">
                {workspaces.map((workspace) => (
                    <div key={workspace.id} className="workspace-card">
                        <h3>{workspace.displayName}</h3>
                        <ul className="board-list">
                            {workspace.boards?.map((board) => (
                                <li key={board.id} className="board-item">
                                    <p>{board.name}</p>
                                    <button
                                        className="set-target-button"
                                        onClick={() => fetchLists(board.id)}
                                    >
                                        Set As Target
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
            <div className="list-id-input">
                <label>Target List ID</label>
                <input
                    type="text"
                    value={selectedList}
                    onChange={(e) => {
                        setSelectedList(e.target.value);
                        onSaveListId(e.target.value);
                    }}
                />
                <button
                    className="update-button"
                    onClick={() => console.log(`Selected List ID: ${selectedList}`)}
                >
                    Update
                </button>
            </div>
            {error && <p className="error">{error}</p>}
        </div>
    );
};

export default TrelloDataForm;
