import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import logo from '../logo2.png';
import '../styles.css'; // Ensure this file has the updated CSS
import { FaUser, FaLock } from 'react-icons/fa';

const Login = () => {
  const { setIsAuthenticated, setUserRole } = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [activeTab, setActiveTab] = useState('login'); // State to manage active tab
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/auth/login', {
        username,
        password,
      });
      const data = response.data;
      if (data.success) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('role', data.role);
        localStorage.setItem('username', data.username);

        setIsAuthenticated(true);
        setUserRole(data.role);

        if (data.role === 'admin') {
          navigate('/admindashboard');
        } else if (data.role === 'writer') {
          navigate('/writerdashboard');
        } else if (data.role === 'sponsor') {
          navigate('/sponsordashboard');
        }
      } else {
        alert('Login failed');
      }
    } catch (error) {
      console.error('Login error:', error);
      alert('Login failed');
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === 'login') {
      navigate('/login');
    } else {
      navigate('/register');
    }
  };

  return (
    <div className="fullscreen-container">
      <div className="login-box">
        <img src={logo} alt="Logo" className="login-logo" />
        <div className="login-title">Welcome to Plotpointe</div>

        <div className="toggle-container">
          <button
            className={`toggle-button ${activeTab === 'login' ? 'active' : ''}`}
            onClick={() => handleTabClick('login')}
          >
            Log In
          </button>
          <button
            className={`toggle-button ${activeTab === 'register' ? 'active' : ''}`}
            onClick={() => handleTabClick('register')}
          >
            Register
          </button>
        </div>

        <form onSubmit={handleLogin}>
          <div className="input-container">
            <FaUser className="input-icon" />
            <input
              type="text"
              placeholder="Your username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <FaLock className="input-icon" />
            <input
              type="password"
              placeholder="Your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button className="submit-button" type="submit">Log In</button>
        </form>

        <p className="forgot-password-link">
          <a href="/forgot-password">Don't remember your password?</a>
        </p>
      </div>
    </div>
  );
};

export default Login;
