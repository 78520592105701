import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Pagination,
  PaginationItem,
  IconButton,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Grid,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TableRowsIcon from "@mui/icons-material/TableRows";
import GridViewIcon from "@mui/icons-material/GridView";
import VideoCard from "./VideoCard";
import axios from "axios";

const Repost = () => {
  const [view, setView] = useState("table");
  const [videoData, setVideoData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(50);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/api/videos/repost");
      const { rows } = response.data;

      if (Array.isArray(rows)) {
        setVideoData(rows);
      } else {
        setVideoData([]); // Ensure videoData is always an array
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching Repost data", error);
      setVideoData([]);
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleViewChange = (event, nextView) => {
    if (nextView !== null) {
      setView(nextView);
    }
  };

  // Filter data based on title or URL
  const filteredData = videoData.filter(
    (video) =>
      (video.title && video.title.toLowerCase().includes(searchText.toLowerCase())) ||
      (video.url && video.url.toLowerCase().includes(searchText.toLowerCase()))
  );

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredData.slice(indexOfFirstRecord, indexOfLastRecord);

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Repost Videos
      </Typography>

      {/* Search bar */}
      <Box sx={{ mb: 3, display: "flex", alignItems: "center" }}>
        <IconButton aria-label="search">
          <SearchIcon />
        </IconButton>
        <TextField
          variant="outlined"
          size="small"
          label="Search by Title or URL"
          value={searchText}
          onChange={handleSearchChange}
          sx={{ width: 300 }}
        />
      </Box>

      {/* Toggle between Grid and Table views */}
      <ToggleButtonGroup
        value={view}
        exclusive
        onChange={handleViewChange}
        aria-label="view toggle"
        sx={{ mb: 3 }}
      >
        <ToggleButton value="grid" aria-label="grid view">
          <GridViewIcon />
        </ToggleButton>
        <ToggleButton value="table" aria-label="table view">
          <TableRowsIcon />
        </ToggleButton>
      </ToggleButtonGroup>

      {loading ? (
        <Typography>Loading...</Typography>
      ) : view === "table" ? (
        <Box sx={{ height: 600 }}>
          <DataGrid
            rows={filteredData}
            columns={[
              { field: "video_id", headerName: "Video ID", width: 150 },
              { field: "url", headerName: "URL", width: 150 },
              {
                field: "details",
                headerName: "Details",
                width: 150,
                renderCell: (params) => (
                  <a href={`/videos/${params.row.video_id}`}>Details</a>
                ),
              },
              { field: "name", headerName: "Sponsor", width: 100 },
              { field: "username", headerName: "Account", width: 100 },
              { field: "writername", headerName: "Writer", width: 100 },
              { field: "views_total", headerName: "Views", width: 100 },
              { field: "likes_total", headerName: "Likes", width: 100 },
              { field: "comments_total", headerName: "Comments", width: 100 },
              { field: "created", headerName: "Timestamp", width: 150 },
            ]}
            pagination
            rowsPerPageOptions={[10, 25, 50]}
            pageSize={recordsPerPage}
            rowCount={filteredData.length}
            page={currentPage - 1}
            onPageChange={(params) => setCurrentPage(params.page + 1)}
            disableRowSelectionOnClick
            components={{ Toolbar: GridToolbar }}
            getRowId={(row) => row.video_id}
          />
        </Box>
      ) : (
        <Grid container spacing={1}>
          {currentRecords.map((video) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              xl={3}
              key={video.video_id}
            >
              <VideoCard video={video} />
            </Grid>
          ))}
        </Grid>
      )}

      {/* Pagination */}
      {view === "grid" && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
          <Pagination
            count={Math.ceil(filteredData.length / recordsPerPage)}
            page={currentPage}
            onChange={(event, page) => setCurrentPage(page)}
            renderItem={(item) => (
              <PaginationItem
                slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                {...item}
              />
            )}
          />
        </Box>
      )}
    </Box>
  );
};

export default Repost;

