import React, { useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth();
  

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('username');
    setIsAuthenticated(false);
    navigate('/');
    window.history.replaceState(null, null, '/'); // Clears history stack
  };
  useEffect(() => {
    handleLogout();
  }, []);

  return null; // No need to render anything, as the user will be redirected
};

export default Logout;