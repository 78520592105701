import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Alert,
  Stack,
  Typography,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Edit } from "@mui/icons-material";

const ManageWriters = () => {
  const [writers, setWriters] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [editingRow, setEditingRow] = useState(null); // Track currently editing row

  useEffect(() => {
    fetchWriters();
  }, []);

  const fetchWriters = async () => {
    try {
      const response = await axios.get("/api/admin/writers");
      setWriters(response.data);
    } catch (error) {
      setError("Error fetching writers");
    }
  };

  const handleEditClick = (id) => {
    setEditingRow(id);
  };

  const handlePaymentScaleChange = async (id, value) => {
    try {
      await axios.put(`/api/admin/writers/${id}`, { payment_scale: value });
      setSuccess("Payment scale updated successfully");
      setEditingRow(null); // Exit edit mode after updating
      fetchWriters(); // Refresh the list after updating
    } catch (error) {
      setError("Error updating payment scale");
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`/api/admin/writers/${id}`);
      if (response.data.success) {
        setSuccess("Writer deleted successfully");
        fetchWriters(); // Refresh the list after deletion
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setError("Error deleting writer");
    }
  };

  const columns = [
    { field: "id", headerName: "#", width: 50 },
    { field: "username", headerName: "Username", width: 300 },
    { field: "name", headerName: "Name", width: 300 },
    {
      field: "payment_scale",
      headerName: "Payment Scale",
      width: 300,
      renderCell: (params) => {
        const isEditing = editingRow === params.row.id;

        return isEditing ? (
          <Select
            value={params.value || ""}
            onChange={(e) =>
              handlePaymentScaleChange(params.row.id, e.target.value)
            }
            size="small"
            sx={{ width: 150, fontSize: "0.8rem" }}
          >
            <MenuItem value="Intern">Intern</MenuItem>
            <MenuItem value="Full Time Employee">Full Time Employee</MenuItem>
          </Select>
        ) : (
          <Stack direction="row" alignItems="center">
            <Typography sx={{ fontSize: "0.8rem" }}>
              {params.value || "N/A"}
            </Typography>
            <IconButton
              size="small"
              onClick={() => handleEditClick(params.row.id)}
              sx={{ ml: 1 }}
            >
              <Edit fontSize="small" />
            </IconButton>
          </Stack>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width:200,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={() => handleDelete(params.row.id)}
            sx={{ fontSize: "0.7rem", padding: "3px 6px" }}
          >
            Delete
          </Button>
        </Stack>
      ),
    },
  ];

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Manage Writers
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {success}
        </Alert>
      )}

      <Box sx={{ height: 600, width: "100%", maxWidth: "1400px" }}>
        <DataGrid
          rows={writers}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20]}
          pagination
          disableSelectionOnClick
          disableColumnMenu
          rowHeight={40}
          headerHeight={40}
          slots={{ toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default ManageWriters;
