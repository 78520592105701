import React, { useState, useEffect } from 'react';
import { Container, Card, Typography, Avatar, Box, Grid, Divider } from '@mui/material';
import axios from 'axios';

// Import logos individually
import obviLogo from '../assets/obvi.jpg';
import bloomLogo from '../assets/bloom.jpg';
import reclipLogo from '../assets/reclip.jpg';
import foriaWellnessLogo from '../assets/floriawellness.jpg';

const AdminDashboard = () => {
  const [sponsorStats, setSponsorStats] = useState([]);

  useEffect(() => {
    const fetchSponsorStats = async () => {
      try {
        const response = await axios.get('/api/sponsors/stats'); // Your API endpoint
        setSponsorStats(response.data);
      } catch (error) {
        console.error('Error fetching sponsor stats:', error);
      }
    };
    fetchSponsorStats();
  }, []);

  if (!sponsorStats.length) return <p>Loading...</p>;

  // Map sponsor names to imported logos
  const sponsorLogos = {
    Obvi: obviLogo,
    Bloom: bloomLogo,
    Reclip: reclipLogo,
    ForiaWellness: foriaWellnessLogo,
  };

  return (
    <Container style={{ marginTop: '20px' }}>
      {sponsorStats.map((sponsor) => (
        <Grid container key={sponsor.name} style={{ marginBottom: '20px' }}>
          <Grid item xs={12}>
            <Card
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '20px',
                borderRadius: '15px',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                width: '1200px',
              }}
            >
              {/* Sponsor Logo */}
              <Avatar
                alt={sponsor.name}
                src={sponsorLogos[sponsor.name]} // Use mapped logo
                sx={{ width: 80, height: 80, marginRight: '20px', borderRadius: '8px' }}
              />

              {/* Sponsor Info */}
              <Box sx={{ flex: 1 }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                  {sponsor.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  @{sponsor.name.toLowerCase()} • videos
                </Typography>
              </Box>

              {/* Total Views */}
              <Box sx={{ textAlign: 'center', marginRight: '30px' }}>
                <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 'medium' }}>
                  Total Views
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#000' }}>
                  {sponsor.totalViews.toLocaleString()}
                  <Box
                    component="span"
                    sx={{
                      marginLeft: '8px',
                      fontSize: '1rem',
                      color: '#555',
                      border: '1px solid #ddd',
                      borderRadius: '20px',
                      padding: '2px 8px',
                    }}
                  >
                    #
                  </Box>
                </Typography>
              </Box>

              {/* Divider */}
              <Divider orientation="vertical" flexItem sx={{ marginX: '20px' }} />

              {/* Total Engagement */}
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 'medium' }}>
                  Total Engagement
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#ff6d00' }}>
                  {sponsor.totalEngagement.toLocaleString()}
                </Typography>
              </Box>
            </Card>
          </Grid>
        </Grid>
      ))}
    </Container>
  );
};

export default AdminDashboard;


