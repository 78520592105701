import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Stack,
  IconButton,
  Typography,
  
} from '@mui/material';
import { DataGrid, GridToolbar,useGridApiRef } from '@mui/x-data-grid';

import 'react-datepicker/dist/react-datepicker.css';
import SearchIcon from '@mui/icons-material/Search';

const PendingWriters = () => {
  const [pendingUsers, setPendingUsers] = useState([]);
  const [filter, setFilter] = useState('All');
  const [roleFilter, setRoleFilter] = useState('All'); // New state for role filter
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedPaymentScale, setSelectedPaymentScale] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const apiRef = useGridApiRef();

  useEffect(() => {
    fetchPendingUsers();
  }, []);

  const fetchPendingUsers = async () => {
    try {
      const response = await axios.get('/api/admin/pendingWriters');
      const usersData = response.data;
      const paymentScaleState = {};

      usersData.forEach((user) => {
        paymentScaleState[user.id] = user.payment_scale || ''; // Ensure there's a default value
      });

      setSelectedPaymentScale(paymentScaleState);
      setPendingUsers(usersData);
    } catch (error) {
      console.error('Error fetching pending users:', error);
    }
  };

  const handleApprove = async (id) => {
    try {
      const paymentScale = selectedPaymentScale[id];
      const user = pendingUsers.find((user) => user.id === id);

      if (user.role === 'writer' && (!paymentScale || paymentScale === '')) {
        alert('Please select a valid payment scale before approving.');
        return;
      }

      await axios.post('/api/admin/approveUser', { id, payment_scale: paymentScale || null });
      fetchPendingUsers(); // Refresh the list after approval
    } catch (error) {
      console.error('Error approving user:', error);
    }
  };

  const handleReject = async (id) => {
    try {
      await axios.post('/api/admin/rejectUser', { id });
      fetchPendingUsers(); // Refresh the list after rejection
    } catch (error) {
      console.error('Error rejecting user:', error);
    }
  };

  const handlePaymentScaleChange = (id, value) => {
    setSelectedPaymentScale((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const filterUsers = () => {
    let filtered = pendingUsers;

    // Apply role filter
    if (roleFilter !== 'All') {
      filtered = filtered.filter((user) => user.role.toLowerCase() === roleFilter.toLowerCase());
    }

    // Apply other filters
    if (filter === 'Approved') {
      filtered = filtered.filter((user) => user.status.toLowerCase() === 'approved');
    } else if (filter === 'Pending') {
      filtered = filtered.filter((user) => user.status.toLowerCase() === 'pending');
    } else if (filter === 'Custom') {
      filtered = filtered.filter((user) => {
        const createdAt = new Date(user.timestamp);
        return createdAt >= startDate && createdAt <= endDate;
      });
    }

    // Apply search query filter
    if (searchQuery) {
      filtered = filtered.filter((user) =>
        user.username.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    return filtered;
  };

  const filteredUsers = filterUsers();

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Pending Accounts
      </Typography>

      {/* Search and Filter Section */}
      <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', gap: 2 }}>
        <FormControl sx={{ minWidth: 150 }}>
          <InputLabel>Role Filter</InputLabel>
          <Select
            value={roleFilter}
            onChange={(e) => setRoleFilter(e.target.value)}
            label="Role Filter"
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Writer">Writer</MenuItem>
            <MenuItem value="Sponsor">Sponsor</MenuItem>
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 150 }}>
          <InputLabel>Status Filter</InputLabel>
          <Select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            label="Status Filter"
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Approved">Approved</MenuItem>
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="Custom">Custom</MenuItem>
          </Select>
        </FormControl>

        <TextField
          label="Search by Username"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          size="small"
        />

        <IconButton>
          <SearchIcon />
        </IconButton>
      </Box>

      <Box sx={{ mb: 2, display: 'flex', gap: 2 }}>
       
      </Box>

      {/* DataGrid Section */}
      <Box sx={{ height: 600, width: 1200}}>
        <DataGrid
          rows={filteredUsers}
          columns={[
            { field: 'id', headerName: 'User ID', width: 100 },
            { field: 'username', headerName: 'Username', width: 100 },
            { field: 'name', headerName: 'Name', width: 100 },
            { field: 'email', headerName: 'Email', width: 150 },
            { field: 'role', headerName: 'Role', width: 100 },
            {
              field: 'payment_scale',
              headerName: 'Payment Scale',
              width: 180,
              renderCell: (params) => (
                params.row.role === 'writer' ? (
                  <Select
                    value={selectedPaymentScale[params.row.id] || ''}
                    onChange={(e) => handlePaymentScaleChange(params.row.id, e.target.value)}
                    displayEmpty
                    disabled={params.row.status.toLowerCase() !== 'pending'}
                  >
                    <MenuItem value="">Select</MenuItem>
                    <MenuItem value="Intern">Intern</MenuItem>
                    <MenuItem value="Full Time Employee">Full Time Employee</MenuItem>
                  </Select>
                ) : (
                  <Typography>Not Applicable</Typography>
                )
              )
            },
            {
              field: 'status',
              headerName: 'Actions',
              width: 200,
              renderCell: (params) => (
                params.row.status.toLowerCase() === 'pending' ? (
                  <Stack direction="row" spacing={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleApprove(params.row.id)}
                    >
                      Approve
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleReject(params.row.id)}
                    >
                      Reject
                    </Button>
                  </Stack>
                ) : (
                  <Typography>{params.row.status}</Typography>
                )
              )
            },
            {
              field: 'timestamp',
              headerName: 'Submitted At',
              width: 200,
              
             
            }
            
          ]}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50]}
          rowCount={filteredUsers.length}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          slots={{ toolbar: GridToolbar }}
        />
      </Box>

    </Box>
  );
};

export default PendingWriters;

