import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ApiKeyForm from './ApiKeyForm';
import TrelloDataForm from './TrelloDataForm';
import AdminSidebar from './AdminSidebar';
import TopBar from './TopBar';
import '../styles.css';
import './Sidebar.css';

const SettingsPage = () => {
    const [apiKey, setApiKey] = useState('');
    const [token, setToken] = useState('');
    const [listId, setListId] = useState('');
    const [editing, setEditing] = useState(false);
    const [currentSection, setCurrentSection] = useState('Trello Settings');

    // Fetch settings on component mount
    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const response = await axios.get('/api/settings');
                const { api_key, token, list_id } = response.data;
                setApiKey(api_key);
                setToken(token);
                setListId(list_id);
            } catch (error) {
                console.error('Error fetching settings:', error);
            }
        };

        fetchSettings();
    }, []);

    // Handle save of new settings
    const handleSave = async (newApiKey, newToken, newListId) => {
        try {
            await axios.post('/api/settings', { apiKey: newApiKey, token: newToken, listId: newListId });
            setApiKey(newApiKey);
            setToken(newToken);
            setListId(newListId);
            setEditing(false);
        } catch (error) {
            console.error('Error saving settings:', error);
        }
    };

    return (
        <div className="dashboard">
            <AdminSidebar setCurrentSection={setCurrentSection} />
            <TopBar currentSection={currentSection} /> {/* Updated TopBar */}
            <div className="main-content">
                
                </div>
                <div className="content">
                    <h1>Trello Connection Settings</h1>
                    <div className="settings-container">
                        <div className="api-settings">
                            <ApiKeyForm
                                apiKey={apiKey}
                                token={token}
                                listId={listId}
                                editing={editing}
                                onEdit={() => setEditing(true)}
                                onSave={(newApiKey, newToken, newListId) => handleSave(newApiKey, newToken, newListId)}
                                onCancel={() => setEditing(false)}
                            />
                        </div>
                        <div className="workspace-settings">
                            {!editing && apiKey && token && listId && (
                                <TrelloDataForm apiKey={apiKey} token={token} listId={listId} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
     
    );
};

export default SettingsPage;
