import React from 'react';
import { useParams } from 'react-router-dom';

const VideoDetailsSponsor = () => {
  const { videoUrl } = useParams();
  const apiKey = "glsa_sodYmoS6MSMXrfjMkxjn1Wg4FZbJmxVr_7f272c1b";  // Replace with your actual API key
  const grafanaUrl = `https://34.31.47.2:3000/d/aduts08jlx05cd?orgId=1&apiKey=${apiKey}&var-video_url=${encodeURIComponent(videoUrl)}`;

  return (
    <div>
      <h2 style={{alignItems:'center',alignContent:'center'}}>Your Video Details</h2>
      <iframe
        src={grafanaUrl}
        width="1300px"
        height="1310px"
        frameBorder="0"
        title="Grafana Dashboard"
      ></iframe>
    </div>
  );
};

export default VideoDetailsSponsor;
