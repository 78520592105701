import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Modal,
  TextField,
  Menu,
  MenuItem,
  Grid,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import ReactECharts from "echarts-for-react";
import dayjs from "dayjs";
import { groupBy } from "lodash"; // Install lodash for grouping
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";

// Utility function to format numbers
const formatNumber = (value) => {
  return Number.isInteger(value) ? value : value.toFixed(2);
};

// TopBar Component
const TopBar = ({ handleMenuOpen }) => {
  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: "#ffffff",
        boxShadow: "none",
        borderBottom: "1px solid #e0e0e0",
      }}
    >
      <Toolbar>
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{
            flexGrow: 1,
            color: "#ef6c00",
            fontWeight: "bold",
            marginLeft: "20px",
          }}
        >
          Plotpointe Sponsor Dashboard
        </Typography>

        <IconButton color="inherit" onClick={handleMenuOpen}>
          <MenuIcon sx={{ color: "#ef6c00" }} />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

const SponsorDashboard = () => {
  const [chartData, setChartData] = useState([]);
  const [sponsorName, setSponsorName] = useState("");
  const [sponsorId, setSponsorId] = useState(null);
  const [startDate, setStartDate] = useState(dayjs().subtract(30, "days"));
  const [endDate, setEndDate] = useState(dayjs());
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchSponsorData = async () => {
    try {
      const username = localStorage.getItem("username");
      if (!username) {
        console.error("No username found in local storage.");
        return;
      }

      const loginResponse = await axios.get(`/api/login`, {
        params: { username },
      });
      const loginId = loginResponse.data.login_id;

      if (!loginId) {
        console.error("No login ID found for the user.");
        return;
      }

      const sponsorResponse = await axios.get(`/api/sponsor`, {
        params: { login_id: loginId },
      });
      const sponsor = sponsorResponse.data;

      if (sponsor && sponsor.sponsor_id) {
        setSponsorId(sponsor.sponsor_id);
        setSponsorName(sponsor.name);
        fetchData(sponsor.sponsor_id);
      } else {
        console.error("No sponsor details found for the login ID.");
      }
    } catch (error) {
      console.error("Error fetching sponsor data:", error);
    }
  };

  const fetchData = async (sponsorId) => {
    try {
      setLoading(true);
      const response = await axios.get(`/api/bigquery/views`, {
        params: {
          sponsorId,
          startDate: startDate.format("YYYY-MM-DD HH:mm:ss"),
          endDate: endDate.format("YYYY-MM-DD HH:mm:ss"),
        },
      });
      setChartData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSponsorData();
  }, []);

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/login";
  };

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const handleApplyDateRange = () => {
    if (sponsorId) {
      fetchData(sponsorId);
    }
    handleModalClose();
  };

  const getCardData = (days) => {
    const recentData = chartData.filter(
      (item) => dayjs().diff(dayjs(item.time), "day") <= days
    );
    const sum = recentData.reduce((sum, item) => sum + item.views, 0);
    return formatNumber(sum) || "No data";
  };

// Aggregate chartData by day
const aggregatedChartData = Object.entries(
  groupBy(chartData, (item) => dayjs(item.time).format("YYYY-MM-DD"))
).map(([date, items]) => ({
  time: date,
  views: items.reduce((sum, item) => sum + item.views, 0), // Sum of views for the day
}));

const chartOptions = {
  tooltip: {
    trigger: "axis",
    backgroundColor: "rgba(244, 81, 30, 0.2)", // Match the graph's line color with a light shade
    borderColor: "#f4511e", // Same as line color
    borderWidth: 1,
    textStyle: { color: "#333" },
    formatter: (params) => {
      const date = params[0]?.axisValue || "N/A";
      const value = params[0]?.value || 0;
      return `
        <div style="min-width: 150px;">
          <div style="font-size: 12px; color: #747575;">${date}</div>
          <div style="font-size: 18px; font-weight: 600; color: #333;">${value.toLocaleString()} Views</div>
        </div>
      `;
    },
    extraCssText: "box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);",
  },
  xAxis: {
    type: "category",
    boundaryGap: false,
    data: aggregatedChartData.map((item) => dayjs(item.time).format("MMM D")),
    axisLabel: {
      formatter: (value, index) => (index % 2 === 0 ? value : ""), // Skip every other label
    },
  },
  yAxis: {
    type: "value",
    axisLabel: {
      formatter: (value) => {
        if (value >= 1e6) return `${(value / 1e6).toFixed(1)}M`;
        if (value >= 1e3) return `${(value / 1e3).toFixed(1)}K`;
        return value;
      },
    },
  },
  series: [
    {
      data: aggregatedChartData.map((item) => item.views),
      type: "line",
      smooth: true,
      lineStyle: { color: "#f4511e" },
      areaStyle: {
        color: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            { offset: 0, color: "rgba(244, 81, 30, 0.4)" },
            { offset: 1, color: "rgba(244, 81, 30, 0)" },
          ],
        },
      },
    },
  ],
};


  return (
    <Box>
      {/* TopBar */}
      <TopBar handleMenuOpen={handleMenuOpen} />

      {/* Menu for Logout */}
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem onClick={handleLogout}>
          <LogoutIcon sx={{ marginRight: 1, color: "#ef6c00" }} />
          Logout
        </MenuItem>
      </Menu>

      {/* Date Range Button */}
      <Box sx={{ position: "absolute", top: 90, right: 40 }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#ef6c00",
            color: "white",
            padding: "8px 16px",
            "&:hover": {
              backgroundColor: "#d9534f",
            },
          }}
          onClick={handleModalOpen}
        >
          Select Date Range
        </Button>
      </Box>

      {/* Date Picker Modal */}
      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2" mb={2}>
            Select Date Range
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(params) => (
                <TextField {...params} fullWidth sx={{ mb: 2 }} />
              )}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
          <Box mt={2} display="flex" justifyContent="space-between">
            <Button
              sx={{
                backgroundColor: "#007bff",
                color: "white",
                "&:hover": { backgroundColor: "#0056b3" },
              }}
              onClick={handleApplyDateRange}
            >
              Apply
            </Button>
            <Button
              sx={{
                backgroundColor: "#28a745",
                color: "white",
                "&:hover": { backgroundColor: "#218838" },
              }}
              onClick={() => {
                setStartDate(dayjs().subtract(30, "days"));
                setEndDate(dayjs());
                setModalOpen(false);
              }}
            >
              Reset
            </Button>
          </Box>
        </Box>
      </Modal>

      <Box sx={{ padding: 4, marginTop: 8 }}>
        {/* Sponsor Name */}
        <Typography variant="h4" sx={{ marginBottom: 4 }}>
          {sponsorName}'s Dashboard
        </Typography>

        {/* Loading Indicator */}
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="400px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {/* Cards */}
            <Grid
              container
              spacing={2}
              justifyContent="center"
              sx={{ marginBottom: 4 }}
            >
              {["Last 7 Days", "Last 14 Days", "Total Views"].map(
                (title, index) => (
                  <Grid item key={index}>
                    <Card
                      sx={{
                        width: "226px",
                        height: "110px",
                        backgroundColor: "#fff",
                        borderRadius: "12px",
                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "10px",
                      }}
                    >
                      <CardContent sx={{ padding: 0, textAlign: "center" }}>
                        <Typography
                          variant="body2"
                          sx={{ color: "rgb(105,97,97)" }}
                        >
                          {title}
                        </Typography>
                        <Typography
                          variant="h5"
                          sx={{ marginTop: "10px", color: "#000" }}
                        >
                          {index === 0
                            ? getCardData(7)
                            : index === 1
                            ? getCardData(14)
                            : getCardData(30)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                )
              )}
            </Grid>

            {/* Chart Card */}
            <Box
              sx={{
                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                borderRadius: "12px",
                backgroundColor: "#fff",
                padding: 3,
                marginTop: 4,
              }}
            >
              <ReactECharts option={chartOptions} style={{ height: "400px" }} />
            </Box>

            {/* Data Grid */}
            <Box
              sx={{
                height: 400,
                marginTop: 4,
                "& .MuiDataGrid-root": {
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #e0e0e0",
                  backgroundColor: "#fff",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#f5f5f5",
                  color: "#333",
                  fontWeight: "bold",
                  fontSize: "14px",
                  borderBottom: "1px solid #ccc",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "1px solid #e0e0e0",
                  fontSize: "14px",
                },
                "& .MuiDataGrid-row": {
                  "&:nth-of-type(even)": {
                    backgroundColor: "#f9f9f9",
                  },
                  "&:hover": {
                    backgroundColor: "#f1f1f1",
                  },
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "1px solid #e0e0e0",
                },
              }}
            >
              <DataGrid
                rows={chartData.map((item, index) => ({
                  id: index,
                  ...item,
                }))}
                columns={[
                  { field: "time", headerName: "Time", flex: 1 },
                  {
                    field: "views",
                    headerName: "Views",
                    flex: 1,
                    renderCell: (params) => formatNumber(params.value),
                  },
                ]}
                pageSize={5}
                disableColumnMenu
                disableSelectionOnClick
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default SponsorDashboard;
