import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Form, Button, Alert, Card, Row, Col, Dropdown, DropdownButton, FormControl, InputGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import './Sidebar.css';
import '../styles.css';

const WriterDashboard = () => {
  const [writer, setWriter] = useState(null);
  const [error, setError] = useState(null);
  const [title, setTitle] = useState('');
  const [googleDocLink, setGoogleDocLink] = useState('');
  const [scripts, setScripts] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchTitle, setSearchTitle] = useState('');
  const [filter, setFilter] = useState('Recent 4');
  const [statusFilter, setStatusFilter] = useState('');
  const [currentSection, setCurrentSection] = useState('Writer Dashboard');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const username = localStorage.getItem('username');

  useEffect(() => {
    const fetchWriterData = async () => {
      if (!username) {
        setError('Username not found in local storage.');
        return;
      }
      try {
        const response = await axios.get(`/api/getWriter?username=${username}`);
        setWriter(response.data);
        fetchScripts(response.data.id);
      } catch (error) {
        console.error('Error fetching writer data:', error);
        setError('Error fetching writer data');
      }
    };
    fetchWriterData();
  }, [username]);

  const fetchScripts = async (writer_id) => {
    try {
      const response = await axios.get(`/api/scripts?writer_id=${writer_id}`);
      setScripts(response.data);
    } catch (error) {
      console.error('Error fetching scripts:', error);
      setError('Error fetching scripts');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!writer) {
      setError('Writer information not loaded yet.');
      return;
    }
    setIsSubmitting(true);

    try {
      const response = await axios.post('/api/scripts', {
        writer_id: writer.id,
        title,
        googleDocLink,
      });
      setScripts([...scripts, response.data]);
      setTitle('');
      setGoogleDocLink('');
      setError(null);
      alert('Approval pending, may take 24-48 hours');
      setIsSubmitting(false);
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.error);
      } else {
        setError('Error submitting script');
      }
      setIsSubmitting(false);
    }
  };

  const getStatusDisplay = (status) => {
    // Normalize input to prevent casing and trimming issues
    const normalizedStatus = status.trim().toLowerCase();
    switch (normalizedStatus) {
        case "approved script. ready for production":
        case "writer submissions (qa)":
        case "finished video":
        return "Pending";
        case "rejected":
        return "Rejected";
        case "posted":
        return "Posted";
        default:
        return "Unknown";
    }
};

  const getStatusStyle = (status) => {
    const displayedStatus = getStatusDisplay(status);
    switch (displayedStatus) {
      case 'Rejected':
        return { color: 'white', backgroundColor: 'red' };
      case 'Pending':
        return { color: 'black', backgroundColor: 'yellow' };
      case 'Posted':
        return { color: 'white', backgroundColor: 'purple' };
      default:
        return { color: 'white', backgroundColor: 'grey' };
    }
  };

  const filterScripts = () => {
    let filteredScripts = scripts;
    if (filter === 'Recent 4') {
      filteredScripts = scripts.slice(0, 4);
    } else if (filter === 'Last 4') {
      filteredScripts = scripts.slice(-4);
    } else if (filter === 'Title') {
      filteredScripts = scripts.filter(script => script.title.toLowerCase().includes(searchTitle.toLowerCase()));
    } else if (filter === 'Custom') {
      filteredScripts = scripts.filter(script => {
        const scriptDate = new Date(script.created_at);
        return scriptDate >= startDate && scriptDate <= endDate;
      });
    }
    if (statusFilter) {
      filteredScripts = filteredScripts.filter(script => getStatusDisplay(script.approval_status) === statusFilter);
    }
    return filteredScripts;
  };

  return (
    <div className="dashboard">
      <div style={{ marginTop: '30px' }}>
        <Container>
          {writer && (
            <div className="welcome-note">
              <h6 style={{ color: '#2596be', fontSize: '20px' }}>Welcome, {writer.name}! What are we writing today?</h6>
              <p style={{ color: 'grey' }}>Writer ID: {writer.id}</p>
            </div>
          )}
          {error && <Alert variant="danger">{error}</Alert>}
          <Row>
            <Col md={8}>
              <h3>New Script Submission</h3>
              <div className="form-container" style={{ width: '1000px' }}>
                <Form onSubmit={handleSubmit}>
                  <Form.Group style={{ marginBottom: '50px' }}>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Google Doc Link</Form.Label>
                    <Form.Control
                      type="url"
                      value={googleDocLink}
                      onChange={(e) => setGoogleDocLink(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Button type="submit" variant="primary" className="button1" disabled={isSubmitting}>
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                  </Button>
                </Form>
              </div>
            </Col>
            <Col md={4} className="left-container d-flex flex-column">
              <h3 style={{ marginLeft: 30 }}>Previous Submissions</h3>
              <div className="filters">
                <DropdownButton id="filter-dropdown" title="Filters" className="mb-3">
                  <Dropdown.Item onClick={() => setFilter('Recent 4')}>Recent 4</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilter('Last 4')}>Last 4</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilter('Custom')}>Custom Date</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilter('Title')}>Title</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={() => setStatusFilter('')}>All Statuses</Dropdown.Item>
                  <Dropdown.Item onClick={() => setStatusFilter('Rejected')}>Rejected</Dropdown.Item>
                  <Dropdown.Item onClick={() => setStatusFilter('Pending')}>Pending</Dropdown.Item>
                  <Dropdown.Item onClick={() => setStatusFilter('Posted')}>Posted</Dropdown.Item>
                </DropdownButton>
                {filter === 'Custom' && (
                  <div className="custom-date-filter mb-3">
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                    <Button onClick={() => setFilter('Custom')}>Apply</Button>
                  </div>
                )}
                {filter === 'Title' && (
                  <InputGroup className="search-title mb-3">
                    <FormControl
                      placeholder="Search by title"
                      value={searchTitle}
                      onChange={(e) => setSearchTitle(e.target.value)}
                    />
                    <Button variant="outline-secondary">
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  </InputGroup>
                )}
              </div>
              <div className="cards-container flex-grow-1">
                <Row>
                  {filterScripts().map((script) => (
                    <Col key={script.id} sm={12} className="mb-3">
                      <Card className="sticky-note">
                        <Card.Body>
                          <Card.Title>{script.title}</Card.Title>
                          <Card.Text>
                            <small>
                              Submitted on: {script.created_at ? new Date(script.created_at).toLocaleDateString() : 'Unknown'}
                            </small>
                          </Card.Text>
                        </Card.Body>
                        <Card.Footer style={getStatusStyle(script.approval_status)}>
                          <small>{getStatusDisplay(script.approval_status)}</small>
                        </Card.Footer>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default WriterDashboard;