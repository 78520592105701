// Dashboard.js
import React, { useEffect, useState } from "react";

import AdminSidebar from "./AdminSidebar";
import "./Sidebar.css";
import "./Dashboard.css";
import TopBar from "./TopBar"; 

const Dashboard = () => {
  // State to handle if data is loading
  const [loading, setLoading] = useState(true);
  const [currentSection, setCurrentSection] = useState("Overview");

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="dashboard">
      <AdminSidebar setCurrentSection={setCurrentSection} />
      <TopBar currentSection={currentSection} /> {/* Updated TopBar */}

      <div className="main-content">
        

          
        </div>

        <iframe
          src="https://34.31.47.2:3000/public-dashboards/f24046fdc73b4c8c8bc019bc1ac4739d"
          width="1300px"
          height="800px"
        ></iframe>
      </div>
   
  );
};

export default Dashboard;
