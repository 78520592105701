import React, { useState } from 'react';
import '../styles.css';

const ApiKeyForm = ({ apiKey, token, listId, editing, onEdit, onSave, onCancel }) => {
    const [newApiKey, setNewApiKey] = useState(apiKey);
    const [newToken, setNewToken] = useState(token);
    const [newListId, setNewListId] = useState(listId);

    return (
        <div className="api-key-form">
            {editing ? (
                <div>
                    <div>
                        <label>API Key:</label>
                        <input
                            type="text"
                            value={newApiKey}
                            onChange={(e) => setNewApiKey(e.target.value)}
                        />
                    </div>
                    <div>
                        <label>Token:</label>
                        <input
                            type="text"
                            value={newToken}
                            onChange={(e) => setNewToken(e.target.value)}
                        />
                    </div>
                    <div>
                        <label>List ID:</label>
                        <input
                            type="text"
                            value={newListId}
                            onChange={(e) => setNewListId(e.target.value)}
                        />
                    </div>
                    <button onClick={() => onSave(newApiKey, newToken, newListId)}>Save</button>
                    <button onClick={onCancel}>Cancel</button>
                </div>
            ) : (
                <div>
                    <p><strong>API Key:</strong> {apiKey}</p>
                    <p><strong>Token:</strong> {token}</p>
                    <p><strong>List ID:</strong> {listId}</p>
                    <button onClick={onEdit}>Edit</button>
                </div>
            )}
        </div>
    );
};

export default ApiKeyForm;
