import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FaBars } from 'react-icons/fa';
import './Sidebar'; // Separate CSS file for reusability

const TopBar = ({ currentSection }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth();
  const currentTime = new Date().toLocaleTimeString();

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('username');
    setIsAuthenticated(false);
    navigate('/');
    window.history.replaceState(null, null, '/');
  };

  return (
    <div className="top-bar">
      <div className="left-section">
        <div className="logo"></div>
        <div className="divider"></div>
        <div className="section-title-container">
          <h1 className="section-title">{currentSection}</h1>
          <img
            alt="videoTooltipIcon"
            className="section-icon"
            src="https://dashboard.northbeam.io/assets/video-tooltip-CVL_XF3v.png"
          />
        </div>
      </div>
      <div className="top-bar-controls">
      <p style={{marginRight:'50px',marginTop:'30px',fontSize:'14px'}}>Last Processed:
       <p className='time-info'>{currentTime}</p>
       </p>
        <FaBars className="menu-icon" onClick={toggleDropdown} />
        {dropdownVisible && (
          <div
            
            style={{
              position: 'absolute',
              top: '45px',
              right: '20px',
              backgroundColor: '#fff',
              border: '1px solid #ddd',
              borderRadius: '5px',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              padding: '15px',
              height:'90px',
              widows:'100px',
              zIndex: 1000,
            }}
          >
            
            <FontAwesomeIcon icon={faSignOutAlt} className="symbol" style={{marginRight:'10px'}} />
            <span className="link-text" style={{ margin:0, cursor: 'pointer' }} onClick={handleLogout}>Logout</span>
           
        
            
          </div>
        )}
      </div>
    </div>
  );
};

export default TopBar;