import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../logo2.png';
import '../styles.css'; // Assuming you have styles defined here

const Register = () => {
  const navigate = useNavigate();

  // Navigate to Writer Register Form
  const handleWriterRegister = () => {
    navigate('/register-writer');
  };

  // Navigate to Sponsor Register Form
  const handleSponsorRegister = () => {
    navigate('/register-sponsor');
  };

  return (
    <div className="fullscreen-container">
      <div className="login-box">
        <img src={logo} alt="Logo" className="login-logo" />
        <div className="login-title">Welcome to Plotpointe</div>
        
        <div className="register-selection-container">
          <button
            button className="submit-button" type="submit"
            onClick={handleWriterRegister}
          >
            Register as Writer
          </button>
          <button
           button className="submit-button" type="submit"
            onClick={handleSponsorRegister}
          >
            Register as Sponsor
          </button>
        </div>
      </div>
    </div>
  );
};

export default Register;
